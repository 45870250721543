import {
  FOG_100,
  OwnUpBody,
  OwnUpDefaultInfoNotification,
  OwnUpSmallSubheadlineRegular
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { LayoutWithAppWrapper } from '../components/layout';
import { ContentWrapper, PageWrapper } from '../components/layout/wrappers';
import { PageTitle } from '../components/typography';

const lenders = [
  'Bank of Newport',
  'Central Bank and Trust',
  'Eclick Lending',
  'Filo',
  'GRMC Lending',
  'Interfirst',
  'McGlone/Homestead Financing',
  'Mutual of Omaha',
  'New American Funding',
  'NJ Lenders',
  'Rockland Federal Credit Union',
  'Success Mortgage Partners'
];

const LendersList = () => {
  const mappedLenders = lenders.map((lenderName) => (
    <OwnUpBody variant="body1" key={lenderName}>
      {lenderName}
    </OwnUpBody>
  ));
  return <React.Fragment>{mappedLenders}</React.Fragment>;
};

const DividerLine = styled.hr`
  height: 1px;
  background-color: ${FOG_100};
  border: none;
  margin: 20px 0;
`;

// eslint-disable-next-line max-lines-per-function
export const Providers = () => (
  <LayoutWithAppWrapper>
    <PageWrapper>
      <ContentWrapper>
        <PageTitle variant="title">Providers</PageTitle>
        <OwnUpDefaultInfoNotification
          $hasIcon={true}
          description="The Own Up Network includes the following lenders who may contact you via telephone or email."
        />
        <OwnUpSmallSubheadlineRegular variant="h1" style={{ marginTop: '40px' }}>
          Our Lenders
        </OwnUpSmallSubheadlineRegular>
        <DividerLine />
        <LendersList />
      </ContentWrapper>
    </PageWrapper>
  </LayoutWithAppWrapper>
);

export default Providers;
